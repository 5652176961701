import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';

import { DatePipe } from '@angular/common';
import {FormGroup} from '@angular/forms'
import { NgForm } from '@angular/forms';
//import {  FormGroup, FormControl, Validators } from '@angular/forms';
import { AppGlobals } from '../service/APPGLOBAL.service';
import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceService } from '../service/service.service';
//export {Eleve} from './eleve';
import * as jsPDF from 'jspdf'
import 'jspdf-autotable';
import dayGridPlugin from '@fullcalendar/daygrid';

import 'jspdf';
//declare let jsPDF;


interface Eleve{
  Numero: string;
  Noms_Prénoms: string;
  Classe: string;
  Mention_Prix: string;
  Sexe: string;
  Etablissement: string;
  Tel_parent: string;
  DateNaissance:string;
  quartier: string;
  idEvent: string;
  note: Number;
}
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe],
 
})

export class DashboardComponent implements OnInit {
  public message='';
  calendarPlugins = [dayGridPlugin]; // important!
  nexschool = [
    {
      id: 1,
      logo: "assets/img/logo/retraite.jpg", //../assets/img/luxéol-cheveux-tarif.png
      nameetab: "Collège La retraite",
      type: "Privé",
      datetenue: "06/05/2020"
  
    },
    {
      id: 1,
      logo: "assets/img/logo/lse.jpg", //../assets/img/luxéol-cheveux-tarif.png
      nameetab: "Lycée de Nsam Efoulan",
      type: "Public",
      datetenue: "06/05/2020"
  
    },
    {
      id: 1,
      logo: "assets/img/logo/lcv.jpg", //../assets/img/luxéol-cheveux-tarif.png
      nameetab: "Lycée  de la Cité Verte",
      type: "Public",
      datetenue: "13/05/2020"
  
    },
    {
      id: 1,
      logo: "assets/img/logo/lba.jpg", //../assets/img/luxéol-cheveux-tarif.png
      nameetab: "Lycée  de Biyem Assi",
      type: "Public",
      datetenue: "13/05/2020"
  
    },
  ];
  
  stats = [
    {
      id: 1,
      logo: "assets/img/logo/lgl.jpg", //../assets/img/luxéol-cheveux-tarif.png
      nameetab: "Lycée  Général Leclerc",
      total: 1093,
      type: "Public",
      inscrits: 1358,
      taux : 80.41,
      premier: "17,75 (1ère C)",
      prix : "01 Ordinateur, 04 clés USB et 991 Bourses de formation  en Informatique pendant les vacances",
  
    },
    {
      id: 2,
      nameetab: "Lycée de Ngoa Ekélé",
      logo: "assets/img/logo/lne.jpg",
      total: "191",
      type: "Public",
      inscrits: 475,
      taux : 40.21,
      premier: "19,50 (5ème)",
      prix : "01 Ordinateur, 04 clés USB et 151 Bourses de formation  en Informatique pendant les vacances",
    },
    {
      id: 3,
      nameetab: "Collège Adventiste de Yaoundé",
      logo: "assets/img/logo/cay.jpg",
      total: "154",
      type: "Public",
      inscrits: 246,
      taux : 63.38,
      premier: "17,50 (4ème A1)",
      prix : "01 Ordinateur, 04 clés USB et 135 Bourses de formation  en Informatique pendant les vacances",
    },
    {
      id: 4,
      nameetab: "Collège F. X. Vogt",
      logo: "assets/img/logo/vogt.jpg",
      total: "102",
      type: "Privé",
      inscrits: 120,
      taux : 85.83,
      premier: "19,50 (4ème B)",
      prix : "01 Ordinateur, 04 clés USB et  91 Bourses de formation en Informatique pendant les vacances",
    },/*
    {
      id: 5,
      nameetab: "Lycée de Biyen Assi",
      logo: "assets/img/logo/vogt.jpg",
      total: "01",
      type: "Public",
      inscrits: 1,
      taux : 100,
      premier: "15.00 (2nde C)",
      prix : "01 Bourse de formation  en Informatique pendant les vacances",
    },*/
  ];
    liste = [
      {
        id: 1,
        name: "EBOUBE DIKE Olvalda",
        classe: "2nde C1",
        etabblissement: "Lyycée Général Leclerc",
        decision: "Boursier (Prix d'excéllence"
      },
      {
        id: 1,
        name: "EBOUBE DIKE Olvalda",
        classe: "2nde C1",
        etabblissement: "Lyycée Général Leclerc",
        decision: "Boursier (Prix d'excéllence"
      },
      {
        id: 1,
        name: "BELLA ASSAN",
        classe: "2nde C1",
        etabblissement: "Lyycée Général Leclerc",
        decision: "Boursier (Prix d'excéllence"
      },
      {
        id: 1,
        name: "PINOKIO",
        classe: "6ème Bil1",
        etabblissement: "Lyycée Général Leclerc",
        decision: "Boursier (Prix d'excéllence"
      },
      {
        id: 1,
        name: "ZENABOU CHERAZARD",
        classe: "Tle A4 ALL",
        etabblissement: "Lyycée Général Leclerc",
        decision: "Boursier (Prix d'excéllence"
      },
      {
        id: 1,
        name: "kwayep amina",
        classe: "6ème c",
        etabblissement: "Lyycée Général Leclerc",
        decision: "Boursier (Prix d'excéllence"
      },
    
    
  ];
  settings = {
    noDataMessage: "Aucun candidat  n'a été trouvé!",
    add: {
      addButtonContent: '<i class="fa fa-2x fa-plus text-info"></i>',
      createButtonContent: '<i class="fa fa-2x fa-check text-success"></i>',
      cancelButtonContent: '<i class="fa fa-2x fa-close text-danger"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="fa fa-2x  fa-pencil text-info"></i>',
      saveButtonContent: '<i class="fa fa-2x  fa-check text-success"></i>',
      cancelButtonContent: '<i class="fa fa-2x fa-close text-danger"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="fa fa-2x fa-trash text-danger"></i>',
      confirmDelete: true,
    },
   // Numero,Noms_Prénoms,Classe,Mention_Prix,Sexe,Etablissement,Tel_parent,DateNaissance,quartier,idEvent,note
    columns: {
      id: {
        title: 'Rang',
        width: '5%',
      },
      Noms_Prénoms: {
        title: 'Nom de l\'élève',
        width: '30%',
      },
      Classe: {
        title: 'Classe',
        width: '15%',
      },
      Etablissement: {
        title: 'Etablissement',
        width: '20%',
        /*filter: {
          type: 'list',
          config: {
            selectText: 'Filtrer .',
            list: [
              { value: 'Lycée Général Leclerc', title: 'Lycée Général Leclerc' },
              { value: 'Lycée de Ngoa Ekélé', title: 'Lycée de Ngoa Ekélé' },
              { value: 'Collège F. X. Vogt', title: 'Collège F. X. Vogt' },
              { value: 'Collège Adventiste de Ydé', title: 'Collège Adventiste de Ydé' },  
              { value: 'Lycée Biyem assi', title: 'Lycée Biyem assi' },
            ],
          },
        } */
      },
      Mention_Prix: {
        title: 'Décision/Mention',
        width: '30%',
      },/*
      note:{
        title: 'Note/20'
      } */
    },
    pager: {
      display: true,
      perPage: 10,
    },
    actions: {
     add: false,
     edit:false,
     delete:false,
      position: 'left',
    },
    attr: {
      class: 'table table-hover'
    }
  };

  





  public leseleves = [];/*
  public el: Eleve;
  public e = new Eleve();*/
  public etab='Tous';
 
  public url1:any;

  
  public items1: any[];

  nbusers = 0;

  
  
 
 
  Etablissement ='Lycée Général Leclerc';
public 


  constructor(private gpdf: AppGlobals,private http: Http,public param: ServiceService,private datePipe: DatePipe, private router: Router) {

    this.recuperereleve().subscribe(
      data => {
        this.items1 = JSON.parse(JSON.stringify(data)); 
        this.nbusers = this.items1.length;
       this.reclassement(this.items1); 
       this.items1 = this.leseleves
      },
        ); 
                  
   }
reclassement(eleve:any){
  var elev:Eleve = <any>{};
  let idx=0;
  this.leseleves = [];
    for(let i=0; i<=eleve.length; i++){
       idx=i+1;
        elev = Object.assign( { id: ""+idx+""} ,eleve[i]);
        this.leseleves[i]=elev;
    }
}
   onrowSelect(event){
    //window.alert(event.data.Numero);
    console.log(event.data);
   }
/**/
   recuperereleve() {
    this.url1=this.param.lien+'affiche_classemt_candi_tout_E.php'
    return this.http
                .get(this.url1)
                .map((data: Response) => data.json())
   }
  recuperereleve1(userSelected: string) {
      if(userSelected==="tous"){
        this.recuperereleve().subscribe(
          data => {
            this.items1 = JSON.parse(JSON.stringify(data)); 
            this.nbusers = this.items1.length;
            this.reclassement(this.items1); 
            this.items1 = this.leseleves;
          },
            );
            
    
      }
    else{
      this.url1=this.param.lien+'affiche_classemt_candi_par_E.php?Etablissement='+userSelected
      return this.http
                .get(this.url1)
                .map((data: Response) => data.json());
      }
  }
  public selectOption(userSelected){
    this.etab = userSelected;
    console.log('User selected is : ' + userSelected);
    this.items1 = [];
    
    this.recuperereleve1(userSelected).subscribe(
      data => {
        this.items1 = JSON.parse(JSON.stringify(data)); 
        this.nbusers = this.items1.length;
        this.reclassement(this.items1); 
       this.items1 = this.leseleves
      },
        );
       

   
    }
  

 
  ngOnInit() {
   // this.reclassement(this.liste);


  }





  showNotification(from, align, messagej,t){
    const type = ['','info','success','warning','danger'];

    const color = Math.floor((Math.random() * 4) + 1);

    $.notify({
        icon: "notifications",
        message: messagej

    },{
        type: type[t],
        timer: 4000,
        placement: {
            from: from,
            align: align
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
}






























}
