import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import { ServiceService } from '../../service/service.service';
import { ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import { Http, Response } from '@angular/http';
//import 'rxjs/add/operator/map';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
    private listTitles: any[];
    location: Location;
    private toggleButton: any;
    private sidebarVisible: boolean;

    nomEtabliF ='';
    anneScolaire='20';

    public url1:any;
    public items1: any[];
    notification=[];

    constructor(private http: Http,location: Location, private element: ElementRef, private router: Router,public param: ServiceService,private ref: ChangeDetectorRef) {


        this.location = location;
        this.sidebarVisible = false;

       if(localStorage.getItem('isconn')=='true'){
            this.nomEtabliF=localStorage.getItem('infoetablissement');
         
        }else{
       
        }

    }
    recupproduit() {
        this.url1=this.param.lien+'recup_produit.php'
        return this.http
        .get(this.url1)
        .map((data: Response) => data.json())
      }
    ngOnInit() {


        this.recupproduit().subscribe(
            data => {
              this.items1 = JSON.parse(JSON.stringify(data)); 

              for (var j=0;j<this.items1.length;j++){ 
                let qtv1=this.items1[j].Quantite;
                let f=+qtv1;
              if(f<10){ 
                this.notification.push('Stock du produit critique: '+this.items1[j].Nom_commercial);
            }
              }




            },
              );



        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    getTitle() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(2);
        }
        titlee = titlee.split('/').pop();

        for (var item = 0; item < this.listTitles.length; item++) {
            if (this.listTitles[item].path === titlee) {
                return this.listTitles[item].title;
            }
        }
        return 'Dashboard';
    }

    Deconnexion() {
        // localStorage.removeItem('user');

        localStorage.removeItem('hostM');
        localStorage.removeItem('hostD');
         localStorage.removeItem('login');
         localStorage.removeItem('pass');
         localStorage.removeItem('nom');
         localStorage.removeItem('token');
           localStorage.removeItem('isconn');
           localStorage.removeItem('user');
         this.router.navigate(['/login']);
         window.location.reload();

     }
    logout() {
        this.param.passok=false;
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        this.router.navigate(['dashboard']);
    };
}
