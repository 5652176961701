import { Component, OnInit } from '@angular/core';
import * as xlsx from 'xlsx';


import * as jsPDF from 'jspdf'
import 'jspdf-autotable';

//import {jsPDF} from 'jspdf';
import { NgForm } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { ServiceService } from '../service/service.service';
import { HttpClient } from '@angular/common/http';
declare var $: any;
import { ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import dayGridPlugin from '@fullcalendar/daygrid';


@Component({
  selector: 'app-importer-produit',
  templateUrl: './importer-produit.component.html',
  styleUrls: ['./importer-produit.component.scss']
})

export class ImporterProduitComponent implements OnInit {
  calendarPlugins = [dayGridPlugin]; // important!
  file: any;
  public items1=[];
  public url1:any;
  nbusers = 0;
  tableau= [];
  constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 



  }

  ngOnInit() {
    this.tableau=localStorage.getItem('user').split(";");
    if (!this.tableau.includes('Paramètre')) {
      this.router.navigate(['Acceil']);
      }

  }
  recupproduit() {
    this.url1=this.param.lien+'recup_produit.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }


  public changeListener(files: FileList){
    console.log(files);
    if(files && files.length > 0) {
       let file : File = files.item(0); 
         console.log(file.name);
         console.log(file.size);
         console.log(file.type);
         let reader: FileReader = new FileReader();
         reader.readAsText(file,'ISO-8859-1');
         reader.onload = (e) => {


       
            
     this.recupproduit().subscribe(
        data => {
          this.items1 = JSON.parse(JSON.stringify(data)); 
          this.nbusers = this.items1.length;


            let csv: string = reader.result as string;
            console.log(csv);
            this.http.get(this.param.lien+'import_produit.php?toto='+csv+'&nbusers='+ this.nbusers).subscribe(data => {
              this.showNotification('top','center','Importation effectué',1);
                 }); 
            },
          );

      
         }
      }
  }




  showNotification(from, align, messagej,t){
    const type = ['','info','success','warning','danger'];
 
    const color = Math.floor((Math.random() * 4) + 1);
 
    $.notify({
        icon: "notifications",
        message: messagej
 
    },{
        type: type[t],
        timer: 4000,
        placement: {
            from: from,
            align: align
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
 }
 
 
}
