import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms'
import { DatePipe } from '@angular/common';
//import { UsersService } from '../services/users.service';
//import { Userdestiny } from '../models/user';
//import { AppGlobals } from '../services/APPGLOBAL.service';


/*import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { PrintDriver } from 'ng-thermal-print/lib/drivers/PrintDriver';*/
//import 'rxjs/add/operator/map';

//import  'jspdf-autotable';

//import jsPDF from 'jspdf';
///import 'jspdf';
//declare let jsPDF;
//import * as jsPDF from 'jspdf';


import * as jsPDF from 'jspdf'
import 'jspdf-autotable';


import { NgForm } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { ServiceService } from '../../service/service.service';
import { HttpClient } from '@angular/common/http';
declare var $: any;
import { ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


@Component({
  selector: 'app-effectuer-vente',
  templateUrl: './effectuer-vente.component.html',
  styleUrls: ['./effectuer-vente.component.scss']
})





export class EffectuerVenteComponent implements OnInit {


 













 
        settings= {
         
     
        noDataMessage: "Aucun produit n'est enrégistré pour le moment!",
        add: {
          addButtonContent: '<i class="fa fa-2x fa-plus text-info"></i>',
          createButtonContent: '<i class="fa fa-2x fa-check text-success"></i>',
          cancelButtonContent: '<i class="fa fa-2x fa-close text-danger"></i>',
          confirmCreate: false,
        },
        edit: {
          editButtonContent: '<i class="fa fa-2x  fa-plus text-info"></i>',
          saveButtonContent: '<i class="fa fa-2x  fa-check text-success"></i>',
          cancelButtonContent: '<i class="fa fa-2x fa-close text-danger"></i>',
          confirmSave: true,
        },
        delete: {
          deleteButtonContent: '<i class="fa fa-2x fa-trash text-danger"></i>',
          confirmDelete: false,
        },
        //hideSubHeader:true,
      
        columns: {

          id: {
            title: 'ID',
            type: 'string',
            editable: false,
            width:'10%',
          },
          n_lot: {
            title: 'Numéro de lot',
            type: 'string',
            editable: false,
            width:'10%',
          },
          Nom_commercial: {
            title: 'Désignation/DCI',
            type: 'string',
            editable: false,
            width:'20%',
          },    
          forme: {
            title: 'Forme',
            type: 'string',
            editable: false,
            width:'15%',
          },
          Prix_populaire: {
           title: 'Prix unitaire',
           type: 'string',
           editable: false,
         },
         Prix_taxe: {
          title: 'Prix avec tax',
          type: 'string',
          editable: false,
        },
        Quantitev: {
          title: 'Stock',
          type: 'string',
          editable: false,
        }

        },
        pager: {
          display: true,
          perPage: 4,
        },
        //selectMode: 'multi',
       /* actions: {
         delete: false,
         add: false,
         edit: true,
         select: false,
          position: 'left',
        },*/
        actions: {
          delete: false,
          add: false,
          edit: false,
          select: false,
          title: 'Ajouter',
          custom: [{  name: 'routeToAPage1', title: `<i class="fa fa-2x fa-plus text-info"></i>` }]
        },
        attr: {
          class: 'table table-hover'
        }
      };
     
      profilpicture='../assets/img/shopping_22120.png';




      tableau= [];

      public NIVEAU= [];
      public url1:any;
      public classe= [];
      Numerofacture = '14kpj3';
      classeR= '478';
      nomp= "";
      nomp1= "";
      eval: string;
      video: string = "https://www.youtube.com/embed/CD-E-LDc384"
      nbusers = 0;
      competence1: any;
      competence= '-';
      public items1p: any;
      public items1: any;
      public items11: any;
      public items2: any;
      public items22=[];
      public imprim: boolean=false;
      grand_total= '0';
      idchange= "";
      QtPT= "";
      nomPT= "";
      NlotPT= "";
      montantpaye= "";
  
     columns = [{DESIGNATION: 'DESIGNATION',prix:'PRIX',qte:'QTE',petiTotal:'TOTAL'}];
    produit = [];



    constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 
    
       
     


      }


      montant(m){
        this.montantpaye=m;


       }



       annuller(){
        this.eval=null;
        this.grand_total='0';
        this.items22=[];
        
      }
      Closes(){
        this.eval=null;
        this.grand_total='0';
        this.items22=[];
        this.recupproduit().subscribe(
          data => {
            this.items1 = JSON.parse(JSON.stringify(data)); 
            this.nbusers = this.items1.length;



            this.items11=[];
         
            for (var i=0;i<this.items1.length;i++){
              


              var x = this.items1[i].Prix_populaire;
              var xL =  localStorage.getItem('TVA');;
              var y = +x;
              var yL = +xL;

            

              var tax=((y*yL)/100)+y;
         
            this.items11.push({id: this.items1[i].id,n_lot: this.items1[i].n_lot, Nom_commercial: this.items1[i].Nom_commercial,
              forme: this.items1[i].forme, Prix_populaire: this.items1[i].Prix_populaire, Prix_taxe: tax,
               Quantitev: this.items1[i].Quantite}) ;
          }




          },
            );



        //window.location.reload();

        
      }

      imprimer(userForm: NgForm) {

        this.eval=null;

        var tail1=document.getElementsByTagName('table')[1].getElementsByTagName('tr').length;
  
    if(this.montantpaye.length===0){

      
      this.showNotification('top','center','Veuillez entrer un montant suppérieur au net à payer!',4);
     }else{
      var tail2=document.getElementsByTagName('table')[1].getElementsByTagName('tr').length;
      var x4 = document.getElementsByTagName('table')[1].getElementsByTagName('tr')[tail2-1].cells[1].innerHTML;
      var z4 = this.montantpaye;
      var y4 = +x4;
      var o4 = +z4;

      var MR=y4-o4;
     


      if(o4<y4){
        this.showNotification('top','center','Le montant remis est inférieur au net à payer',4);
     }else if(tail1==1){
      this.showNotification('top','center','Le pagné est vide',4);
       }else{

     


      this.recupnumfacture().subscribe(
        data => {
          this.Numerofacture = JSON.parse(JSON.stringify(data)); 
   
                //this.imprim=true;selected
      this.nomp="";
      this.nomp1="";
      var tail=document.getElementsByTagName('table')[1].getElementsByTagName('tr').length;
      for (var i=0;i<tail-1;i++){   
        var id=document.getElementsByTagName('table')[1].getElementsByTagName('tr')[i].cells[1].innerHTML; 
        var n_lot=document.getElementsByTagName('table')[1].getElementsByTagName('tr')[i].cells[2].innerHTML;
        var forme=document.getElementsByTagName('table')[1].getElementsByTagName('tr')[i].cells[4].innerHTML;
       var designation=document.getElementsByTagName('table')[1].getElementsByTagName('tr')[i].cells[3].innerHTML;
       var prix=document.getElementsByTagName('table')[1].getElementsByTagName('tr')[i].cells[6].innerHTML;
       var qte=document.getElementsByTagName('table')[1].getElementsByTagName('tr')[i].cells[7].innerHTML;
       var petiTotal=document.getElementsByTagName('table')[1].getElementsByTagName('tr')[i].cells[8].innerHTML;
     console.log(designation+"--"+prix+"--"+qte+"--"+petiTotal);
    
     var stockp=0;
     for (var j=0;j<this.items22.length;j++){   
       if(n_lot===this.items22[j].N_lot&&id===this.items22[j].id){
       stockp=this.items22[j].stock;
       break;
           }
          }
          console.log(designation+"--"+prix+"--"+stockp+"--"+petiTotal);

     if(i==tail-2){
   this.nomp=this.nomp+designation.toUpperCase()+","+forme.toUpperCase()+","+prix+","+qte+","+petiTotal
   this.nomp1=this.nomp1+n_lot+","+designation+","+prix+","+qte+","+petiTotal+","+stockp+","+id+","+forme
  }else{
    this.nomp=this.nomp+designation.toUpperCase()+","+forme.toUpperCase()+","+prix+","+qte+","+petiTotal+";"
    this.nomp1=this.nomp1+n_lot+","+designation+","+prix+","+qte+","+petiTotal+","+stockp+","+id+","+forme+";"
  }

     
      }
      var totalg=document.getElementsByTagName('table')[1].getElementsByTagName('tr')[tail-1].cells[1].innerHTML;
    

   
      var x5 = this.montantpaye;
      var z5 = totalg;
      var y5 = +x5;
      var o5 = +z5;
      var MR=(y5-o5).toFixed(2);

   


  this.eval= this.param.lien+"generate/generator_pdf.php?toto="+ this.nomp+'&total='+totalg+'&nomPharma='+localStorage.getItem("nomPharma")
  +'&bpPharma='+localStorage.getItem("bpPharma")+'&villePharma='+localStorage.getItem("villePharma")+
  '&login='+localStorage.getItem("login")+'&nom='+localStorage.getItem("nom")+'&telpharma='+localStorage.getItem("telpharma")
  +'&Numerofacture='+this.Numerofacture+'&Montantremis='+this.montantpaye+'&Montantrembourse='+MR;


  document.getElementById("openModalButton").click();



 this.http.get(this.param.lien+'add_recu.php?toto='+this.nomp1+'&numrecu='
  +this.Numerofacture+'&MontantRemis='+ this.montantpaye
  +'&MontantRembourse='+MR+'&login='+localStorage.getItem("login")).subscribe(data => {

       });


 
       },
          );
      



}
}
} 
  


close(toto,prix){

  var p=+prix;
  let f1=+this.grand_total;
  this.grand_total=(f1-p).toFixed(2);
var t=+toto;
  this.items22.splice(t, 1) ;//enlève 1 élément a l'indice t
}


/*edit(index,Quantitev,ID,Nom_commercial){
  this.QtPT= Quantitev;
  this.nomPT= Nom_commercial;
  this.NlotPT= ID;
this.idchange=ID;

}
valid(userForm: NgForm) {


  let vr=0;
    let j8 = userForm.value.QT;
    let u8 = +j8;
    for (var i=0;i<this.items11.length;i++){
      if(this.items11[i].id===this.idchange){
  
      let rr=this.items11[i]. Quantitev;
      let rr1=+rr;
      if(rr1<u8){
        vr=1;
        break
      }
      }
    }
  
  if(vr==1){
  
    this.showNotification('top','center','Stock demandé non disponible' ,4);
  }else{
    this.grand_total= '0';
    for (var i=0;i<this.items22.length;i++){
  
      if(this.items22[i].id===this.idchange){
          
        var x8 = userForm.value.QT;
        var p8 = this.items22[i].Prix_taxe;
        var y8 = +x8;
        var t8 = +p8;
        var g8 = y8*t8;
        console.log("qt= "+y8+" prix="+t8+" total="+g8)
        this.items22[i].Quantitev=userForm.value.QT;
        this.items22[i].total=g8.toFixed(2);
       // VE = Math.round(VE* 1000) / 1000
     }
     var o8 = this.items22[i].total;
     var r8 = +o8;
     let tt=+this.grand_total
     this.grand_total=(tt+r8).toFixed(2);
  
    }
  }
     }
*/



diminuer(index,Quantitev,ID,Nom_commercial) {
  this.QtPT= Quantitev;
  this.nomPT= Nom_commercial;
  this.NlotPT= ID;
this.idchange=ID;

  let vr=0;
    let j8 = this.QtPT;
    let u8k = +j8;
    let u8 = u8k-1;
    if(u8<1){
      vr=1;
    }
  
  if(vr==1){
  
    this.showNotification('top','center','Cette action est impossible' ,4);
  }else{
    this.grand_total= '0';
    for (var i=0;i<this.items22.length;i++){
  
      if(this.items22[i].id===this.idchange){
          
        var x8 = u8;
        var p8 = this.items22[i].Prix_taxe;
        var y8 = +x8;
        var t8 = +p8;
        var g8 = y8*t8;
        console.log("qt= "+y8+" prix="+t8+" total="+g8)
        this.items22[i].Quantitev=u8;
        this.items22[i].total=g8.toFixed(2);
       // VE = Math.round(VE* 1000) / 1000
     }
     var o8 = this.items22[i].total;
     var r8 = +o8;
     let tt=+this.grand_total
     this.grand_total=(tt+r8).toFixed(2);
  
    }
  }
     }

ajouter(index,Quantitev,ID,Nom_commercial) {
  this.QtPT= Quantitev;
  this.nomPT= Nom_commercial;
  this.NlotPT= ID;
this.idchange=ID;

  let vr=0;
    let j8 = this.QtPT;
    let u8k = +j8;
    let u8 = u8k+1;
    for (var i=0;i<this.items11.length;i++){
      if(this.items11[i].id===this.idchange){
  
      let rr=this.items11[i]. Quantitev;
      let rr1=+rr;
      if(rr1<u8){
        vr=1;
        break
      }
      }
    }
  
  if(vr==1){
  
    this.showNotification('top','center','Stock demandé non disponible' ,4);
  }else{
    this.grand_total= '0';
    for (var i=0;i<this.items22.length;i++){
  
      if(this.items22[i].id===this.idchange){
          
        var x8 = u8;
        var p8 = this.items22[i].Prix_taxe;
        var y8 = +x8;
        var t8 = +p8;
        var g8 = y8*t8;
        console.log("qt= "+y8+" prix="+t8+" total="+g8)
        this.items22[i].Quantitev=u8;
        this.items22[i].total=g8.toFixed(2);
       // VE = Math.round(VE* 1000) / 1000
     }
     var o8 = this.items22[i].total;
     var r8 = +o8;
     let tt=+this.grand_total
     this.grand_total=(tt+r8).toFixed(2);
  
    }
  }
     }







      ngOnInit() {
      
        this.tableau=localStorage.getItem('user').split(";");
        if (!this.tableau.includes('Paramètre')) {
          this.router.navigate(['Acceil']);
          }


              this.recupproduit().subscribe(
                data => {
                  this.items1 = JSON.parse(JSON.stringify(data)); 
                  this.nbusers = this.items1.length;



                  this.items11=[];
               
                  for (var i=0;i<this.items1.length;i++){
                    


                    var x = this.items1[i].Prix_populaire;
                    var xL =  localStorage.getItem('TVA');;
                    var y = +x;
                    var yL = +xL;

                  

                    var tax=((y*yL)/100)+y;
               
                  this.items11.push({id: this.items1[i].id,n_lot: this.items1[i].n_lot, Nom_commercial: this.items1[i].Nom_commercial,
                    forme: this.items1[i].forme, Prix_populaire: this.items1[i].Prix_populaire, Prix_taxe: tax,
                     Quantitev: this.items1[i].Quantite}) ;
                }




                },
                  );
    
      }
      recupproduit() {
        this.url1=this.param.lien+'recup_produit_VENTE.php'
        return this.http
        .get(this.url1)
        .map((data: Response) => data.json())
      }
      recup_produit_unique() {
        this.url1=this.param.lien+'recup_produit_unique.php'
        return this.http
        .get(this.url1)
        .map((data: Response) => data.json())
      }
      recupnumfacture() {
        this.url1=this.param.lien+'recup_num_facture.php'
        return this.http
        .get(this.url1)
        .map((data: Response) => data.json())
      }



      Custom(event){

     
        var trouve=0;
        for (var i=0;i<this.items22.length;i++){

         if(this.items22[i].id===event.data.id){
          trouve=1;
        }
        }
   let qq=event.data.Quantitev;
   let qqq=+qq;

        if(trouve==1){

         
       
          this.showNotification('top','center','le produit '+event.data.Nom_commercial+' existe déjà dans la commande' ,4);
        }else if(qqq<=0){
        
          this.showNotification('top','center','Stock non disponible' ,4);
        
        }else{
        var x = event.data.Quantitev;
        var z = event.data.Prix_taxe;
        var y = 1;
        var o = +z;
        var total=y*o;
       
       // event.newData['total'] = total; 
        //event.confirm.resolve(event.newData);
        
          this.items22.push({ id: event.data.id,N_lot: event.data.n_lot, Nom_commercial: event.data.Nom_commercial,
            forme: event.data.forme,  Prix_populaire: event.data.Prix_populaire, Prix_taxe: event.data.Prix_taxe,
             Quantitev: 1, total: event.data.Prix_taxe, stock: event.data.Quantitev}) ;
     

      
       let g=+this.grand_total;
        this.grand_total=(g+total).toFixed(2);
       // this.grand_total=this.grand_total.toFixed(2);
        
       //event.confirm.resolve();
      }
      }



      onrowSelect(event){
        
//lengthkkk
      }
  

     

  
      showNotification(from, align, messagej,t){
        const type = ['','info','success','warning','danger'];
    
        const color = Math.floor((Math.random() * 4) + 1);
    
        $.notify({
            icon: "notifications",
            message: messagej
    
        },{
            type: type[t],
            timer: 4000,
            placement: {
                from: from,
                align: align
            },
            template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
              '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
              '<i class="material-icons" data-notify="icon">notifications</i> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              '</div>' +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>'
        });
    }
    
    
    }
    