import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {


  motApplication: any[];
  parametre: string = 'toto';
  items2: any[];
  data2: any;
  public url1:any;
  public login: string= '';
  public pass: string= '';
  public passok: boolean=false;
  public nom: string= '';
  public id_utilisateur: string= '';
  public id_PATIENT: string= '';
  public nomU: string= '';
  public pnomU: string= '';
  public langue: string= '';
  public bd: string= '';
  infoEtablissement: any[];
  public lien0: string= 'http://localhost/backend/';
  readonly lien: string = 'https://backend.destiny-innovation.com/';
  constructor() { }

}