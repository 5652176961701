import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import {MatMenuModule} from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';

import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // <-- Import PdfJsViewerModule module
import { ChartsModule } from 'ng2-charts';

import {
  AgmCoreModule
} from '@agm/core';
import {
  MatButtonModule,
  MatInputModule,
  MatRippleModule,
  MatFormFieldModule,
  MatTooltipModule,
  MatSelectModule
} from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { EffectuerVenteComponent } from './vente/effectuer-vente/effectuer-vente.component';
import { ViewPdfComponent } from './vente/view-pdf/view-pdf.component';
import {  SafePipe } from './vente/effectuer-vente/effectuer-vente.component';
import { AppGlobals } from './service/APPGLOBAL.service';
import { ImprimerComponent } from './imprimer/imprimer.component';
import { ImporterProduitComponent } from './importer-produit/importer-produit.component';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    MatMenuModule,
    PdfJsViewerModule,
    ChartsModule,
    FullCalendarModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    })
  ],
  declarations: [
    DashboardComponent,
    UserProfileComponent,
    AppComponent,
    ImporterProduitComponent,
    ViewPdfComponent,
    SafePipe, 
    ImprimerComponent,
    EffectuerVenteComponent,

  ],

  providers: [AppGlobals],
  bootstrap: [AppComponent]
})
export class AppModule { }
