import { Component, OnInit } from '@angular/core';

import {FormGroup} from '@angular/forms'
import { DatePipe } from '@angular/common';
//import { UsersService } from '../services/users.service';
//import { Userdestiny } from '../models/user';
//import { AppGlobals } from '../services/APPGLOBAL.service';


/*import { PrintService, UsbDriver, WebPrintDriver } from 'ng-thermal-print';
import { PrintDriver } from 'ng-thermal-print/lib/drivers/PrintDriver';*/
//import 'rxjs/add/operator/map';

//import  'jspdf-autotable';

//import jsPDF from 'jspdf';
///import 'jspdf';
//declare let jsPDF;
//import * as jsPDF from 'jspdf';


import * as jsPDF from 'jspdf'
import 'jspdf-autotable';

//import {jsPDF} from 'jspdf';
import { NgForm } from '@angular/forms';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { ServiceService } from '../service/service.service';
import { HttpClient } from '@angular/common/http';
declare var $: any;
import { ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe} from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-imprimer',
  templateUrl: './imprimer.component.html',
  styleUrls: ['./imprimer.component.scss']
})
export class ImprimerComponent implements OnInit {


  settings1= {
   

    noDataMessage: "Aucun inventaire enregistré!",
    add: {
      addButtonContent: '<i class="fa fa-2x fa-plus text-info"></i>',
      createButtonContent: '<i class="fa fa-2x fa-check text-success"></i>',
      cancelButtonContent: '<i class="fa fa-2x fa-close text-danger">cancel</i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="fa fa-2x  fa-pencil text-info"></i>',
      saveButtonContent: '<i class="fa fa-2x  fa-check text-success"></i>',
      cancelButtonContent: '<i class="fa fa-2x fa-close text-danger"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="fa fa-2x fa-trash text-danger"></i>',
      confirmDelete: true,
    },
    columns: {

      id: {
       title: 'Iventaire N°',
       type: 'string',
       editable: false,
     },
     date: {
        title: 'Date',
        type: 'string',
     
      },
      heure: {
         title: 'Heure',
         type: 'string',
      
       }
    },
  
  
    pager: {
      display: true,
      perPage: 7,
    },
    actions: {
      delete: false,
      add: false,
      edit: false,
      select: false,
      title: 'Detail',
      custom: [{  name: 'routeToAPage', title: `<img src="../assets/img/icons8-export-pdf-96.png">` }]
    },
    attr: {
      class: 'table table-hover'
    }
  };


  settings2= {
   

    noDataMessage: "Aucunne vente enregistrée!",
    add: {
      addButtonContent: '<i class="fa fa-2x fa-plus text-info"></i>',
      createButtonContent: '<i class="fa fa-2x fa-check text-success"></i>',
      cancelButtonContent: '<i class="fa fa-2x fa-close text-danger">cancel</i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="fa fa-2x  fa-pencil text-info"></i>',
      saveButtonContent: '<i class="fa fa-2x  fa-check text-success"></i>',
      cancelButtonContent: '<i class="fa fa-2x fa-close text-danger"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="fa fa-2x fa-trash text-danger"></i>',
      confirmDelete: true,
    },
    columns: {

      numrecu: {
       title: 'Reçu N°',
       type: 'string',
       editable: false,
     },
     datefacruration: {
        title: 'Date',
        type: 'string',
     
      },
       login: {
          title: 'Caissier',
          type: 'string',
       
        },
        MontantRemis: {
           title: 'Montant Remis',
           type: 'string',
        
         },
         MontantRembourse: {
            title: 'Montant Remboursé',
            type: 'string',
         
          }
    },
    
    pager: {
      display: true,
      perPage: 7,
    },
    actions: {
      delete: false,
      add: false,
      edit: false,
      select: false,
      title: 'Detail',
      custom: [{  name: 'routeToAPage', title: `<img src="../assets/img/adobe-acrobat-pdf-file-document-512.png">` }]
    },
    attr: {
      class: 'table table-hover'
    }
  };











  eval: string;
  nomp= "";
  public url1:any;
  public items1: any[];
  public dataRecup:any[];
  public myData:number;
  public items3: any[];
  public items4: any[];
  public items3j: any[];
  tableau= [];
  public items11: any[];
  public items11K: any[];
  public items11P: any[];
  constructor(private http: Http, private router: Router,public param: ServiceService,private http1: HttpClient) { 



    this.recup_duplicata_recu().subscribe(
      data => {
        this.items11K = JSON.parse(JSON.stringify(data)); 
      },
        );


    this.recupficheinventaire().subscribe(
      data => {
        this.items11 = JSON.parse(JSON.stringify(data)); 
      },
        );

  }

  ngOnInit() {
    this.tableau=localStorage.getItem('user').split(";");
    if (!this.tableau.includes('Paramètre')) {
      this.router.navigate(['Acceil']);
      }





          this.recupproduit().subscribe(
            data => {
              this.items4 = JSON.parse(JSON.stringify(data));
              localStorage.setItem('PRODUITINVENTAIRE', JSON.stringify(  this.items4)); 

            },
            );     
  }

  recup_produit_duplicata_recu(idrecu:string) {
    this.url1=this.param.lien+'recup_produit_duplicata_recu.php?idrecu='+idrecu
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recup_duplicata_recu() {
    this.url1=this.param.lien+'recup_duplicata_recu.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupficheinventaire() {
    this.url1=this.param.lien+'recup_fiche_inventaire.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recup_ventemoi_inventaire() {
    this.url1=this.param.lien+'recup_vente_moi_inventaire.php'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupproduit() {
    this.url1=this.param.lien+'recup_produit_inventaire.php?'
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }
  recupproduitl(id: String,date: String,heure: String) {
    this.url1=this.param.lien+'recup_inventaire_save.php?id='+id+'&date='+date+'&heure='+heure
    return this.http
    .get(this.url1)
    .map((data: Response) => data.json())
  }

















  export(){
    // Col spans and row spans
    var doc = new jsPDF('L', 'pt');
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.setFontStyle('bold');
    doc.text('REPUBLIQUE DU CAMEROUN', 100, 50);
    doc.text('REPUBLIC OF CAMEROON', 600, 50);
    doc.text('PAIX-TRAVAIL-PATRIE', 120, 70);
    doc.text('PEACE-WORK-FATHERLAND', 590, 70);
    doc.text('-----------------', 140, 85);
    doc.text('-----------------', 640, 85);
    doc.text('MINISTERE DE LA SANTE PUBLIQUE', 100, 105);
    doc.text('MINISTRY OF PUBLIC HEALTH', 590, 105);
    doc.setFontSize(14);
    doc.text('FICHE d\'INVENTAIRE N°'+ localStorage.getItem('id_inventaire')  , 360, 130);
    doc.setFontSize(12);
    doc.setFontStyle('italic');

    doc.text('Région: '+localStorage.getItem('region'), 50, 170);
    doc.text('Aire de santé: '+localStorage.getItem('aire_sante'), 300, 170);
    doc.text('Date de l\'inventaire:____________', 600, 170);

    doc.text('District de santé: '+localStorage.getItem('district'), 50, 200);
    doc.text('Nom de la structure: '+localStorage.getItem('nomPharma'), 300, 200);

    var img = new Image();
    img.src = 'assets/img/Farmacia1.png';
    doc.addImage(img, 'png', 400, 20, 80, 80);

    for (var i = 0; i < this.dataRecup.length; i++) {
        var row = this.dataRecup[i];
        if (i % 5 === 0) {
        row['id'] = {rowSpan: 14, content: i / 5 + 1, styles: {valign: 'middle', halign: 'center'}};
        }
    }
    let head = [
    [
        {content: 'Num.', colSpan: 1, styles: {halign: 'center'}},
        {content: 'Désignation', colSpan: 4, styles: {halign: 'center'}},
        {content: 'Numéro de lot', colSpan: 1, styles: {halign: 'center'}},
        {content: 'Date de péremtion', colSpan: 1, styles: {halign: 'center'}},
        {content: 'Qantité en stock', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Stock D.U', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Ecart', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Prix unitaire', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Valeur en stock réel', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Valeur écart', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Observations', colSpan: 1, styles: {halign: 'center',}},
    ],
        [
          {content: 'N°', colSpan: 1, styles: {halign: 'center'}},
          {content: 'Nom(C)', colSpan: 1, styles: {halign: 'center',}},
          {content: 'Dosage', colSpan: 1, styles: {halign: 'center',}},
          {content: 'Forme G.', colSpan: 1, styles: {halign: 'center',}},
          {content: 'Conditionnement', colSpan: 1, styles: {halign: 'center',}},
          {content: 'NL', colSpan: 1, styles: {halign: 'center',}},
          {content: 'DP', colSpan: 1, styles: {halign: 'center',}},
          {content: 'QS', colSpan: 1, styles: {halign: 'center',}},
          {content: 'SDU', colSpan: 1, styles: {halign: 'center',}},
          {content: 'E', colSpan: 1, styles: {halign: 'center',}},
          {content: 'PU', colSpan: 1, styles: {halign: 'center',}},
          {content: 'VSR', colSpan: 1, styles: {halign: 'center',}},
          {content: 'VE', colSpan: 1, styles: {halign: 'center',}},
          {content: 'Obs', colSpan: 1, styles: {halign: 'center',}},
          ]
    ]
    doc.autoTable({
      headerStyles: {
        lineWidth: 1,
        lineColor: [110,110,110 ],
        fillColor: [183,183,183 ],
        textColor: "#000000"
    },
    startY: 220,
    head: head,
    body: this.dataRecup,
    theme: 'grid'
    });
    doc.save('Inventaire.pdf');
    }







    bodyRows() {
      this.dataRecup=[];
        let body = [];
        var itemNew = [];
    
        this.items4= [];
        this.items1 = [];

        this.recupficheinventaire().subscribe(
          data => {
            this.items3j = JSON.parse(JSON.stringify(data));
            let id="IV00"+this.items3j.length
            localStorage.setItem('id_inventaire',id);   
           },);


        this.recup_ventemoi_inventaire().subscribe(
          data => {
            this.items1 = JSON.parse(JSON.stringify(data));
            var TRD = [];
            TRD=  JSON.parse(localStorage.getItem('PRODUITINVENTAIRE'));
           
            for (var i=0;i< this.items1.length;i++){
             
            
              let Quantite_actuel=0;
              let pu=0;
              let Date_expiration='';
              for (var f=0;f< TRD.length;f++){

              if(TRD[f].n_lot===this.items1[i].N_lot){
                 Quantite_actuel=TRD[f].Quantite;
                 pu=TRD[f].prix_inventaire;
                 Date_expiration=TRD[f].Date_expiration;
              }
            }
              /*this.recupproduit(this.items1[i].N_lot).subscribe(
                data => {
                  this.items4 = JSON.parse(JSON.stringify(data));
                  console.log(this.items4[0].Quantite+" ggggggggggggggggggggggggggggg");
                  localStorage.setItem('Quantite_actuel',this.items4[0].Quantite);    
                  localStorage.setItem('pu', this.items4[0].Prix_populaire);    
                  localStorage.setItem('Date_expiration',this.items4[0].Date_expiration);    
  
                },
                );*/
    
               
           //   console.log(this.items1[i].N_lot+"fffffVEND"+ this.items1[i].Quantite+"fffffAC"+ localStorage.getItem('Quantite_actuel'));
              

                  let Quantite_vendu=this.items1[i].Quantite;
                  let Quantite_vendu1=+Quantite_vendu;
                  let Quantite_actuel1=Quantite_actuel;
                  let Q_St=Quantite_vendu1+Quantite_actuel1;
                  let S_D_U=Q_St-Quantite_vendu1;

                  let pu1=pu;
                  let ecat=Q_St-S_D_U;
                  let prixecat=ecat*pu1;


                
                   let VSR=pu1*S_D_U;
                  itemNew.push({N:(i+1),nom:  this.items1[i].Nom_commercial,dosage: this.items1[i].Dosage,
                    forme:this.items1[i].forme,conditionnemt:  this.items1[i].Conditionnement,n_l: this.items1[i].N_lot,
                    d_p: Date_expiration,Q_S: Q_St,S_D_U: S_D_U,
                    ecat:ecat,prix_u: pu1,VSR: VSR.toFixed(2),VE:prixecat,ob: ' '}) ; 


               
        
                      if(i==this.items1.length-1){
                        this.nomp=this.nomp+(i+1)+";"+this.items1[i].Nom_commercial+";"+this.items1[i].Dosage+";"+this.items1[i].forme
                        +";"+this.items1[i].Conditionnement+";"+this.items1[i].N_lot+";"+Date_expiration
                        +";"+Q_St+";"+S_D_U+";"+ecat+";"+pu1+";"+VSR.toFixed(2)+";"+prixecat
                        +";"+"-"
                       }else{
                        this.nomp=this.nomp+(i+1)+";"+this.items1[i].Nom_commercial+";"+this.items1[i].Dosage+";"+this.items1[i].forme
                        +";"+this.items1[i].Conditionnement+";"+this.items1[i].N_lot+";"+Date_expiration
                        +";"+Q_St+";"+S_D_U+";"+ecat+";"+pu1+";"+VSR.toFixed(2)+";"+prixecat
                        +";"+"-"+"|"
        
                       }

                    
         


            }
            itemNew.forEach(element => {      
              var temp = [element.N,element.nom,element.dosage,element.forme,element.conditionnemt,element.n_l
                ,element.d_p,element.Q_S,element.S_D_U,element.ecat,element.prix_u,element.VSR,element.VE
                ,element.ob];
              body.push(temp);});   
              this.dataRecup=body;
              this.export(); 
       
              this.http.get(this.param.lien+'add_fiche_inventaire.php?toto='+this.nomp+'&id='+localStorage.getItem('id_inventaire')).subscribe(data => {
              });


                      


          },
              );
  



































            console.log("popoooooooooooooooooooo"+this.dataRecup);
        
              
   return 1;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////



export1(date: String,id: String){
  // Col spans and row spans
  var doc = new jsPDF('L', 'pt');
  doc.setFontSize(12);
  doc.setTextColor(0);
  doc.setFontStyle('bold');
  doc.text('REPUBLIQUE DU CAMEROUN', 100, 50);
  doc.text('REPUBLIC OF CAMEROON', 600, 50);
  doc.text('PAIX-TRAVAIL-PATRIE', 120, 70);
  doc.text('PEACE-WORK-FATHERLAND', 590, 70);
  doc.text('-----------------', 140, 85);
  doc.text('-----------------', 640, 85);
  doc.text('MINISTERE DE LA SANTE PUBLIQUE', 100, 105);
  doc.text('MINISTRY OF PUBLIC HEALTH', 590, 105);
  doc.setFontSize(14);
  doc.text('FICHE d\'INVENTAIRE N° '+id, 360, 130);
  doc.setFontSize(12);
  doc.setFontStyle('italic');

  doc.text('Région: '+localStorage.getItem('region'), 50, 170);
  doc.text('Aire de santé: '+localStorage.getItem('aire_sante'), 300, 170);
  doc.text('Date de l\'inventaire: '+date, 600, 170);

  doc.text('District de santé: '+localStorage.getItem('district'), 50, 200);
  doc.text('Nom de la structure: '+localStorage.getItem('nomPharma'), 300, 200);

  var img = new Image();
  img.src = 'assets/img/Farmacia1.png';
  doc.addImage(img, 'png', 400, 20, 80, 80);

  for (var i = 0; i < this.dataRecup.length; i++) {
      var row = this.dataRecup[i];
      if (i % 5 === 0) {
      row['id'] = {rowSpan: 14, content: i / 5 + 1, styles: {valign: 'middle', halign: 'center'}};
      }
  }
  let head = [
  [
      {content: 'Num.', colSpan: 1, styles: {halign: 'center'}},
      {content: 'Désignation', colSpan: 4, styles: {halign: 'center'}},
      {content: 'Numéro de lot', colSpan: 1, styles: {halign: 'center'}},
      {content: 'Date de péremtion', colSpan: 1, styles: {halign: 'center'}},
      {content: 'Qantité en stock', colSpan: 1, styles: {halign: 'center',}},
      {content: 'Stock D.U', colSpan: 1, styles: {halign: 'center',}},
      {content: 'Ecart', colSpan: 1, styles: {halign: 'center',}},
      {content: 'Prix unitaire', colSpan: 1, styles: {halign: 'center',}},
      {content: 'Valeur en stock réel', colSpan: 1, styles: {halign: 'center',}},
      {content: 'Valeur écart', colSpan: 1, styles: {halign: 'center',}},
      {content: 'Observations', colSpan: 1, styles: {halign: 'center',}},
  ],
      [
        {content: 'N°', colSpan: 1, styles: {halign: 'center'}},
        {content: 'Nom(C)', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Dosage', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Forme G.', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Conditionnement', colSpan: 1, styles: {halign: 'center',}},
        {content: 'NL', colSpan: 1, styles: {halign: 'center',}},
        {content: 'DP', colSpan: 1, styles: {halign: 'center',}},
        {content: 'QS', colSpan: 1, styles: {halign: 'center',}},
        {content: 'SDU', colSpan: 1, styles: {halign: 'center',}},
        {content: 'E', colSpan: 1, styles: {halign: 'center',}},
        {content: 'PU', colSpan: 1, styles: {halign: 'center',}},
        {content: 'VSR', colSpan: 1, styles: {halign: 'center',}},
        {content: 'VE', colSpan: 1, styles: {halign: 'center',}},
        {content: 'Obs', colSpan: 1, styles: {halign: 'center',}},
        ]
  ]
  doc.autoTable({
    headerStyles: {
      lineWidth: 1,
      lineColor: [110,110,110 ],
      fillColor: [183,183,183 ],
      textColor: "#000000"
  },
  startY: 220,
  head: head,
  body: this.dataRecup,
  theme: 'grid'
  });
  doc.save('Inventaire.pdf');
  }







  bodyRows1(id: String,date: String,heure: String) {
    this.dataRecup=[];
      let body = [];
      var itemNew = [];


      this.recupproduitl(id,date,heure).subscribe(
        data => {
          this.items1= [];
          this.items1 = JSON.parse(JSON.stringify(data));
          
          for (var i=0;i< this.items1.length;i++){


     
          itemNew.push({N:this.items1[i].num,nom:  this.items1[i].nom,dosage: this.items1[i].dosage,
            forme:this.items1[i].forme,conditionnemt:  this.items1[i].conditionemt,n_l: this.items1[i].num_lot,
            d_p:this.items1[i].date_perem,Q_S:  this.items1[i].quantite_en_stock,S_D_U: this.items1[i].stock_DU,
            ecat:this.items1[i].ecart,prix_u: this.items1[i].prix_U,VSR: this.items1[i].valeur_en_stock,
            VE: this.items1[i].valeur_ecart,ob: this.items1[i].obs}) ; 



        }
      
  
        itemNew.forEach(element => {      
          var temp = [element.N,element.nom,element.dosage,element.forme,element.conditionnemt,element.n_l
            ,element.d_p,element.Q_S,element.S_D_U,element.ecat,element.prix_u,element.VSR,element.VE
            ,element.ob];
          body.push(temp);
             });   

      this.dataRecup=body;
      this.export1(date,id); 


        },
          );

       
 return 1;
}


Custom(event){

  this.bodyRows1(event.data.id,event.data.date,event.data.heure);
 
            }


            Custom1(event){

    this.recup_produit_duplicata_recu(event.data.numrecu).subscribe(
      data => {

        this.items11P=[];
        this.eval="";
        this.nomp="";
        this.items11P = JSON.parse(JSON.stringify(data)); 
        
        var totalg=0;
        for (var i=0;i<this.items11P.length;i++){   
          var id=this.items11P[i].id; 
          var n_lot=this.items11P[i].N_lot;
          var forme=this.items11P[i].forme;
         var designation=this.items11P[i].nom_p;
         var prix=this.items11P[i].prix_tax;
         var qte=this.items11P[i].Quantite;
         var petiTotal=this.items11P[i].sous_total;

         var kj = +petiTotal;
         totalg=totalg+kj;
       console.log(designation+"--"+prix+"--"+qte+"--"+petiTotal);
  

  
       if(i==this.items11P.length-1){
     this.nomp=this.nomp+designation.toUpperCase()+","+forme.toUpperCase()+","+prix+","+qte+","+petiTotal

    }else{
      this.nomp=this.nomp+designation.toUpperCase()+","+forme.toUpperCase()+","+prix+","+qte+","+petiTotal+";"

    }
  
       
        }


 
   
        var x5 = this.items11P[0].MontantRemis;
        var y5 = +x5;

        var MR=(y5-totalg).toFixed(2);
  
     
  
  
    this.eval= this.param.lien+"generate/generator_duplicata_pdf.php?toto="+ this.nomp+'&total='+totalg+'&nomPharma='+localStorage.getItem("nomPharma")
    +'&bpPharma='+localStorage.getItem("bpPharma")+'&villePharma='+localStorage.getItem("villePharma")+
    '&login='+localStorage.getItem("login")+'&nom='+localStorage.getItem("nom")+'&telpharma='+localStorage.getItem("telpharma")
    +'&Numerofacture='+event.data.numrecu+'&Montantremis='+x5+'&Montantrembourse='+MR+'&date='+this.items11P[0].datefacruration;
  
  
    document.getElementById("openModalButton").click();





      },
        );

    
            }
}
  
  
  

 

/* downloadPDF(){



  this.eval= this.param.lien+"generate/generator_inventaire_pdf.php?toto="+ this.nomp;


  document.getElementById("openModalButton").click();
  }*/

