import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ImporterProduitComponent } from './importer-produit/importer-produit.component';
import { DashboardComponent } from './dashboard/dashboard.component';
const routes: Routes =[
 


  { path: 'dashboard',      component: DashboardComponent },
  { path: 'importer-produit',      component: ImporterProduitComponent },
  { path: '',               redirectTo: 'dashboard', pathMatch: 'full' }
]; //http://localhost:4204/#/importer-produit

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
       useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
