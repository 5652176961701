import { Component, OnInit } from '@angular/core';
import { Http, Response } from '@angular/http';
import { ServiceService } from '../../service/service.service';
import PerfectScrollbar from 'perfect-scrollbar';


declare const $: any;


//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    collapse?: string;
    type?: string;
    icontype?: string;
    children?: ChildrenItems2[];
}
export interface ChildrenItems2 {
    path?: string;
    title?: string;
    ab?: string;
    type?: string;
}


//Menu Items



export const ROUTES: RouteInfo[] = [{
        path: '/dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'dashboard'
    },{
        path: '/ModifPass',
        title: 'Modifier le mot de passe',
        type: 'link',
        icontype: 'network_locked'
    },{
        path: '/user-profile',
        title: 'Getion des utilisateurs',
        type: 'link',
        icontype: 'supervised_user_circle'
    },{
        path: '/Categorie',
        title: 'Créer une catégirie',
        type: 'link',
        icontype: 'control_point_duplicate'
    },{
        path: '/EnregProduit',
        title: 'Enregistrer un produit',
        type: 'link',
        icontype: 'save_alt'
    },{
        path: '/ConsulterProduit',
        title: 'Consulter les produits',
        type: 'link',
        icontype: 'filter_none'
    },{
        path: '/ModifierProduit',
        title: 'Modifier un produit',
        type: 'link',
        icontype: 'square_foot'
    },{
        path: '/SupprimerProduit',
        title: 'Supprimer un produit',
        type: 'link',
        icontype: 'stop_screen_share'
    },{
        path: '/EffectuerVente',
        title: 'Effectuer une Vente',
        type: 'link',
        icontype: 'local_grocery_store'
    },{
        path: '/Parametre',
        title: 'Paramètre',
        type: 'link',
        icontype: 'perm_data_setting'
    },{
        path: '/Gestionstock',
        title: 'Gestion du stock',
        type: 'link',
        icontype: 'storage'
    },{
        path: '/Journal',
        title: 'Journal',
        type: 'link',
        icontype: 'wrap_text'
    },{
        path: '/Imprimer',
        title: 'Imprimer',
        type: 'link',
        icontype: 'local_printshop'
    },{
        path: '/GetionInventaire',
        title: 'Gestion de L\'inventaire',
        type: 'link',
        icontype: 'line_style'
    },{
        path: '/AnnullerVente',
        title: 'Annuller une Vente',
        type: 'link',
        icontype: 'delete_sweep'
    },{
        path: '/ImporterProduit',
        title: 'Importer les Produits',
        type: 'link',
        icontype: 'label_important'
    },{
        path: '/EnregistrerPrixPourInv',
        title: 'Modifier prix pour inventaire',
        type: 'link',
        icontype: 'settings_brightness'
    }
    
];


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {

    ROUTES1: RouteInfo[] = [];
    public menuItems: any[];
    tableau= [];

    constructor(private http: Http,private param: ServiceService) { 



    }
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {


     

       this.menuItems = ROUTES.filter(menuItem => menuItem);




    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
   
    

}
